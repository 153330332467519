import { PdpCssVariables } from 'config/branding/interfaces/pdp';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const pdpCssVariables: PdpCssVariables = {
    '--color-border-thumbnail': 'var(--color-base-black-25)',
    '--color-border-thumbnail-active': 'var(--color-base-brand-black)',
    '--color-bg-gallery-button': 'var(--color-base-white)',
    '--color-bg-gallery-button-hover': 'var(--color-base-brand-red)',
    '--color-text-gallery-button-hover': 'var(--color-base-white)',
    '--color-bg-swipe-indicator': 'var(--color-base-brand-grey)',
    '--color-bg-swipe-indicator-active': 'var(--color-base-brand-red)',
    '--color-bg-gallery-placeholder': 'var(--color-base-brand-lightgrey)',
    '--color-bg-model-size': 'var(--color-base-white)',
    '--color-bg-pdp-flipclock': 'var(--color-base-brand-lightgrey)',
    '--color-bg-pdp-important-content': 'var(--color-base-brand-lightgrey)',
    '--color-bg-variant-sale-badge': 'var(--color-base-error)',
    '--color-border-pdp-accordion-content': 'var(--color-base-brand-black)',
    '--color-text-pdp-flipclock': 'var(--color-base-brand-black)',
    '--color-text-pdp-info': 'var(--color-base-brand-grey)',
    '--color-text-variant-sale-badge': 'var(--color-base-white)',
};
