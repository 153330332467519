import { CssThemeVariable } from '@activebrands/core-web/types/css-types';

interface GeneralCssVariables {
    [key: CssThemeVariable]: CssThemeVariable | string;
}

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const generalCssVariables: GeneralCssVariables = {
    // KARI TRAA NEW VARIABLES
    // Add new variables here and remove the old ones when all components are updated to use the new variables.

    // ----------------------------//
    // Global General Variables
    // ---------------------------//

    '--color-text': 'var(--color-base-brand-black)',
    '--color-border': 'var(--color-base-brand-black)',
    '--color-bg': 'var(--color-base-white)',
    '--color-bg-inverted': 'var(--color-base-brand-black)',
    '--color-link': 'var(--color-base-brand-red)',

    // ----------------------------//
    // Background
    // ----------------------------//

    '--color-bg-subtle': 'var(--color-base-brand-lightgrey)',
    '--color-bg-list-bullet': 'var(--color-base-brand-black)',

    // basket
    '--color-divider-basket': 'var(--color-base-brand-black)',
    '--color-text-shipping': 'var(--color-base-brand-grey)',

    // ----------------------------//
    // Input
    // ---------------------------//

    '--color-text-input': 'var(--color-base-brand-grey)',
    '--color-border-input': 'var(--color-base-black-25)',
    '--color-text-input-placeholder': 'var(--color-base-brand-grey-dark)',
    '--color-border-input-inverted': 'var(--color-base-brand-black)',

    // Checkbox input
    '--color-bg-checkbox-inverted-checked': 'var(--color-base-white)',
    '--color-bg-checkbox-checked': 'var(--color-base-brand-black)',
    '--color-border-checkbox-checked': 'var(--color-base-brand-black)',
    '--color-border-checkbox-unchecked': 'var(--color-base-brand-black)',
    '--color-label-checkbox-loading': 'var(--color-base-brand-grey)',
    '--color-label-checkbox-disabled': 'var(--color-base-brand-grey)',

    // Radio buttons
    '--color-bg-radio-button-highlightsecondary-checked': 'var(--color-base-brand-red)',
    '--color-bg-radio-button-inverted': 'var(--color-base-white)',
    '--color-bg-radio-button-inverted-checked': 'var(--color-base-brand-black)',
    '--color-border-radio-button': 'var(--color-base-brand-black)',
    '--color-border-radio-button-inverted': 'var(--color-base-white)',
    '--color-border-radio-button-inverted-checked': 'var(--color-base-brand-black)',
    '--color-border-radio-button-highlightsecondary-checked': 'var(--color-base-brand-red)',

    // Unatuthenticated
    '--color-border-unauthenticated': 'var(--color-base-brand-black)',

    // Act rewards
    '--color-text-act-module': 'var(--color-base-white)',
    '--color-bg-act-vouchers': 'var(--color-base-white)',

    // ----------------------------//
    // Text
    // ---------------------------//

    '--color-text-subtle': 'var(--color-base-brand-grey)',
    '--color-text-pagination': 'var(--color-base-brand-black)',
    '--color-text-pagination-subtle': 'var(--color-base-brand-grey)',

    // ----------------------------//
    // PLP
    // ---------------------------//
    '--color-border-variant-image-active': 'var(--color-base-brand-black)',
    '--color-border-variant-image-hover': 'var(--color-base-brand-grey)',
    '--color-text-variant-label': 'var(--color-base-brand-grey)',

    // ----------------------------//
    // Navigation
    // ---------------------------//

    '--color-text-nav-heading': 'var(--color-base-brand-black)',

    // ----------------------------//
    // Gradient
    // ---------------------------//

    '--color-bg-gradient': 'var(--color-base-white-10)',

    // ----------------------------//
    // Newsletter
    // ---------------------------//

    '--color-text-newsletter-default': 'var(--color-base-brand-black)',
    '--color-bg-newsletter-primary': 'var(--color-base-white)',
    '--color-border-newsletter-input': 'var(--color-base-brand-black)',

    // ---------------------------//
    // Banner
    // ---------------------------//

    // Primary
    '--color-bg-banner-brand-primary': 'var(--color-base-white)',
    '--color-text-banner-brand-primary': 'var(--color-base-brand-black)',
    '--color-border-banner-brand-primary': 'var(--color-base-brand-black)',

    // Secondary
    '--color-bg-banner-brand-secondary': 'var(--color-base-brand-black)',
    '--color-text-banner-brand-secondary': 'var(--color-base-white)',
    '--color-border-banner-brand-secondary': 'var(--color-base-white)',

    // ----------------------------//
    // Article Card
    // ---------------------------//

    '--color-article-card-published-date': 'var(--color-base-brand-grey)',

    // Account
    '--color-border-account': 'var(--color-base-black)',
    '--color-bg-account': 'var(--color-base-brand-lightgrey)',
    '--color-bg-navigation-account': 'var(--color-base-white)',
    '--color-bg-account-shortcuts': 'var(--color-base-white)',
    '--color-bg-account-subtle': 'var(--color-base-brand-grey-light)',
    '--color-border-account-shortcuts': 'var(--color-base-brand-grey-light)',
    '--color-border-account-interests': 'var(--color-base-black)',
    '--color-text-account': 'var(--color-base-black)',
    '--color-text-active-navigation': 'var(--color-base-white)',
    '--color-bg-navigation-active': 'var(--color-base-black)',
    '--color-text-subtle-account': 'var(--color-base-brand-grey)',
    '--color-border-menu-items': 'var(--color-base-black)',
    '--color-text-active': 'var(--color-base-brand-grey-dark)',
    '--color-bg-orders': 'var(--color-base-white)',
    '--color-bg-gradient-meny': 'var(--color-base-white-75)',

    // ----------------------------//
    // Form
    // ---------------------------//

    '--color-bg-form-loading': 'var(--color-base-lightgrey-50)',
    '--color-border-form': 'var(--color-base-black-25)',

    // ----------------------------//
    // Select
    // ---------------------------//

    '--color-border-select-list': 'var(--color-base-black-25)',
    '--color-bg-select-list': 'var(--color-base-white)',
    '--color-bg-select': 'var(--color-base-black)',

    // ----------------------------//
    // Added to basket
    // ---------------------------//

    '--color-border-added-to-basket': 'var(--color-base-black-10)',

    // ----------------------------//
    // Notificaiton
    // ---------------------------//

    '--color-boxShadow-notification-wrapper': 'var(--color-base-black-25)',
    '--color-bg-notificaiton-wrapper': 'var(--color-base-white)',

    // ----------------------------//
    // Beta Form
    // ---------------------------//

    '--color-text-beta-form': 'var(--color-base-white)',
    '--color-bg-beta-form': 'var(--color-base-brand-red)',
    '--color-border-beta-form': 'var(--color-base-black-25)',

    // ----------------------------//
    // Images
    // ----------------------------//

    '--color-bg-image': 'var(--color-base-brand-lightgrey)',

    // ----------------------------//
    // Filter
    // ---------------------------//
    '--color-bg-filter': 'var(--color-base-white)',
    '--color-border-filter': 'var(--color-base-black)',

    // Sticky filter bar
    '--color-bg-sticky-filter-bar': 'var(--color-base-white)',
    '--color-bg-sticky-filter-bar-active': 'var(--color-base-brand-red)',
    '--color-border-sticky-filter-bar': 'var(--color-base-black)',
    '--color-text-sticky-filter-bar': 'var(--color-base-white)',

    // ----------------------------//
    // Quickshop
    // ---------------------------//
    '--color-border-buttons-quickshop': 'var(--color-base-black)',
    '--color-text-buttons-quickshop-disabled': 'var(--color-base-brand-grey)',

    //OLD VARIABLES
    //TODO: Remove these when all components are updated to use the new variables
    // ----------------------------//
    // Text
    // ---------------------------//

    '--color-text-accent': 'var(--color-base-error)',
    '--color-text-disabled': 'var(--color-base-brand-black)',
    '--color-text-error': 'var(--color-base-error)',
    '--color-text-inverted': 'var(--color-base-temp-blue-1)',
    '--color-text-range-value-filter': 'var(--color-base-brand-black)',
    '--color-text-site-selector': 'var(--color-base-white)',

    // ----------------------------//
    // Swatches
    // ----------------------------//
    '--color-swatches-border-color-highlight-inner': 'var(--color-base-white)',
    '--color-swatches-border-color-highlight': 'var(--color-base-brand-black)',
    '--color-swatches-border-color': 'var(--color-base-brand-black)',

    // Backdrop
    '--backdrop-content-top': 'var(--dynamic-main-content-top)',
    '--backdrop-content-transition-duration': 'var(--duration-fast)',
    '--backdrop-content-transition-timing-function': 'var(--ease)',
    '--backdrop-overlay-content-zindex': 'var(--zindex-overlay)',

    // Breadcrumbs
    '--color-text-breadcrumbs-color-default': 'var(--color-base-brand-black)',
    '--color-text-breadcrumbs-color-active': 'var(--color-base-brand-grey)',
    '--padding-content-breadcrumbs': 'var(--margin-content)',

    // ----------------------------//
    // Background
    // ----------------------------//
    '--color-bg-accent': 'var(--color-base-temp-blue-4)',
    '--color-bg-error': 'var(--color-base-temp-blue-6)',
    '--color-bg-highlight': 'var(--color-base-white)',
    '--color-bg-secondary': 'var(--color-base-white)',
    '--color-bg-contrast': 'var(--color-base-white)',
    '--color-bg-white': 'var(--color-base-white)',

    // ----------------------------//
    // Background borders
    // ----------------------------//
    '--color-bg-navigation-header': 'var(--color-base-white)',
    '--color-border-subtle': 'var(--color-base-temp-blue-9)',
    '--color-border-inverted': 'var(--color-base-temp-blue-1)',
    '--color-border-accent': 'var(--color-base-error)',
    '--color-border-error': 'var(--color-base-error)',
    '--color-border-pagination-arrow-icon': 'var(--color-base-brand-black)',
    '--color-border-navigation': 'var(--color-base-brand-black)',

    // ----------------------------//
    // Contrast
    // ----------------------------//

    '--color-contrast-lowest': 'var(--color-base-white)',
    '--color-contrast-lower': 'var(--color-base-temp-blue-9)',
    '--color-contrast-low': 'var(--color-base-temp-blue-9)',
    '--color-contrast-medium': 'var(--color-base-temp-blue-7)',
    '--color-contrast-high': 'var(--color-base-temp-blue-9)',
    '--color-contrast-higher': 'var(--color-base-temp-blue-9)',
    '--color-contrast-dark': 'var(--color-base-temp-blue-10)',

    // ----------------------------//
    // Shadow
    // ----------------------------//
    '--color-shadow': 'var(--color-base-temp-blue-7)',
    '--color-shadow-inverted': 'var(--color-base-temp-blue-1)',
    '--shadow-drop': '--color-base-temp-blue-9',

    // ACT colors
    '--color-text-voucher-subtle': 'var(--color-base-temp-blue-1)',

    // ----------------------------//
    // Header Overlays
    // ----------------------------//
    '--color-bg-header-overlay': 'var(--color-base-white)',

    // ----------------------------//
    // Banner
    // ----------------------------//

    // Accent
    '--color-bg-banner-accent': 'var(--color-base-temp-blue-5)',
    '--color-text-banner-accent': 'var(--color-base-temp-blue-1)',

    // Attention
    '--color-bg-banner-attention': 'var(--color-base-error)',
    '--color-text-banner-attention': 'var(--color-base-temp-blue-1)',

    // ----------------------------//
    // Navigation
    // ----------------------------//
    '--width-overlays': '400px',
    '--color-bg-navigation': 'var(--color-base-temp-blue-1)',
    '--color-text-navigation': 'var(--color-base-brand-black)',

    // ----------------------------//
    // Badges
    // ----------------------------//

    // ----------------------------//
    // Modules
    // ----------------------------//
    '--border-radius-module': '8px',

    // Default
    '--color-bg-badge': '--color-base-temp-blue-1',
    '--color-text-badge': 'var(--color-base-temp-blue-7)',

    // Brand primary
    '--color-bg-badge-brand-primary': 'var(--color-base-temp-blue-9)',
    '--color-text-badge-brand-primary': 'var(--color-base-temp-blue-1)',

    // Accent
    '--color-bg-badge-accent': '--color-base-temp-blue-1',
    '--color-text-badge-accent': 'var(--color-base-error)',

    // ----------------------------//
    // Tags
    // ----------------------------//
    '--color-bg-article-tag': 'var(--color-base-temp-blue-9)',
    '--color-text-article-tag': 'var(--color-base-temp-blue-1)',
    '--color-bg-order-tag': 'var(--color-base-temp-blue-5)',

    // ----------------------------//
    // Preview
    // ----------------------------//
    '--color-bg-preview': 'var(--color-base-temp-blue-7)',
    '--color-bg-preview-button': 'var(--color-base-temp-blue-7)',

    // Category buttons
    '--color-text-category-button': 'var(--color-base-temp-blue-9)',
    '--color-bg-category-button': 'var(--color-base-temp-blue-9)',
    '--color-text-category-button-active': 'var(--color-base-temp-blue-1)',
    '--color-bg-category-button-active': 'var(--color-base-temp-blue-9)',
    '--color-text-category-button-hover': 'var(--color-base-temp-blue-1)',
    '--color-bg-category-button-hover': 'var(--color-base-temp-blue-9)',
    '--color-border-button': 'var(--color-base-temp-blue-1)',
};
